// composables/useWordPressMedia.ts

interface MediaItem {
  id: number;
  date: string;
  date_gmt: string;
  guid: {
    rendered: string;
  };
  title: {
    rendered: string;
  };
  media_type: string;
  mime_type: string;
  source_url: string;
  media_details?: {
    width?: number;
    height?: number;
    file?: string;
    sizes?: {
      [key: string]: {
        file: string;
        width: number;
        height: number;
        mime_type: string;
        source_url: string;
      };
    };
  };
}

export interface ProcessedMediaItem {
  id: number;
  title: string;
  date: string;
  type: string;
  mime: string;
  url: string;
  uploadFolder: string;
  sizes: Record<string, string>;
  relativePath: string;
}

interface MediaFetchOptions {
  folder?: string;
  mediaType?: string;
  limit?: number;
  page?: number;
  perPage?: number;
}

export const useWordPressMedia = () => {
  const BASE_URL = "https://blog.gotongroyong.org/wp-json/wp/v2";

  const mediaItems = useState<MediaItem[]>("wp-media-items", () => []);
  const processedItems = useState<ProcessedMediaItem[]>(
    "wp-processed-items",
    () => [],
  );
  const isLoading = useState<boolean>("wp-media-loading", () => false);
  const error = useState<string | null>("wp-media-error", () => null);

  /**
   * Extract folder information from a media item
   */
  const extractUploadFolder = (item: MediaItem): string => {
    if (!item.media_details?.file) return "unknown";

    const pathParts = item.media_details.file.split("/");
    if (pathParts.length > 1) {
      pathParts.pop(); // Remove filename
      return pathParts.join("/");
    }

    return "unknown";
  };

  /**
   * Process a media item into a more usable format
   */
  const processMediaItem = (item: MediaItem): ProcessedMediaItem => {
    // Get upload folder
    const uploadFolder = extractUploadFolder(item);

    // Get available sizes
    const sizes: Record<string, string> = {};
    if (item.media_details?.sizes) {
      Object.entries(item.media_details.sizes).forEach(
        ([sizeName, sizeData]) => {
          sizes[sizeName] = sizeData.source_url;
        },
      );
    }

    // Create processed item
    return {
      id: item.id,
      title: item.title.rendered,
      date: item.date,
      type: item.media_type,
      mime: item.mime_type,
      url: item.source_url,
      uploadFolder,
      sizes,
      relativePath: item.source_url.replace(
        "https://blog.gotongroyong.org/wp-content/uploads/",
        "",
      ),
    };
  };

  /**
   * Fetch media items with folder filtering capability
   */
  const fetchMediaFromFolder = async (options: MediaFetchOptions = {}) => {
    const { folder, mediaType, limit, page = 1, perPage = 100 } = options;

    isLoading.value = true;
    error.value = null;

    try {
      // Initial fetch parameters
      const fetchParams: Record<string, any> = {
        page,
        per_page: perPage,
        orderby: "date",
        order: "desc",
      };

      // Add media type filter if specified
      if (mediaType) {
        fetchParams.media_type = mediaType;
      }

      // Fetch initial data
      const { data: responseData } = await useFetch<MediaItem[]>(
        `${BASE_URL}/media`,
        {
          params: fetchParams,
          key: `wp-media-${folder || "all"}-${mediaType || "all"}-${page}-${perPage}`,
        },
      );

      if (!responseData.value) {
        throw new Error("No media data returned");
      }

      // Process all items
      let allItems = responseData.value.map(processMediaItem);

      // Filter by folder if specified
      if (folder) {
        allItems = allItems.filter((item) => item.uploadFolder === folder);
      }

      // Apply limit if specified
      if (limit && allItems.length > limit) {
        allItems = allItems.slice(0, limit);
      }

      // Update state
      processedItems.value = allItems;

      return allItems;
    } catch (err) {
      console.error("Error fetching media:", err);
      const errorMessage =
        err instanceof Error ? err.message : "Unknown error fetching media";
      error.value = errorMessage;
      return [];
    } finally {
      isLoading.value = false;
    }
  };

  const fetchAvailableFolders = async (): Promise<string[]> => {
    try {
      const { data: responseData } = await useFetch<MediaItem[]>(
        `${BASE_URL}/media`,
        {
          params: {
            per_page: 100, // Get a good sample size
          },
          key: "wp-media-folders",
        },
      );

      if (!responseData.value) {
        return [];
      }

      const folders = new Set<string>();
      responseData.value.forEach((item) => {
        const folder = extractUploadFolder(item);
        if (folder !== "unknown") {
          folders.add(folder);
        }
      });

      return Array.from(folders).sort();
    } catch (err) {
      console.error("Error fetching folders:", err);
      return [];
    }
  };

  return {
    // State
    mediaItems,
    processedItems,
    isLoading,
    error,

    // Primary methods
    fetchMediaFromFolder,
    fetchAvailableFolders,

    // Helper methods
    processMediaItem,
    extractUploadFolder,
  };
};
