<template>
  <v-container>
    <h2 class="text-h4 text-primary font-weight-bold text-center mb-6">
      Dokumentasi Kegiatan
    </h2>

    <div v-if="isLoading" class="d-flex justify-center my-8">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div
      v-else-if="error"
      class="text-center my-8 pa-4 bg-error-lighten-4 rounded-lg"
    >
      <p class="text-body-1">{{ error }}</p>
      <v-btn color="primary" class="mt-4" @click="loadFolderMedia">
        Coba Lagi
      </v-btn>
    </div>

    <template v-else>
      <Carousel
        v-bind="carouselConfig"
        :breakpoints="breakpoints"
        :settings="carouselSettings"
        class="documentation-carousel"
      >
        <Slide v-for="(media, index) in mediaItems" :key="media.id">
          <v-card width="100%" class="mx-2 carousel__item overflow-hidden">
            <v-img
              v-if="media && media.sizes"
              :src="getBestImageUrl(media)"
              :alt="media.title || `Dokumentasi kegiatan ${index + 1}`"
              width="100%"
              aspect-ratio="1.5"
              cover
              class="carousel-image"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>

      <!-- <div class="d-flex justify-center mt-6">
        <v-btn
          color="primary"
          variant="outlined"
          to="/dokumentasi"
          class="text-capitalize"
        >
          Lihat Semua Dokumentasi
        </v-btn>
      </div> -->
    </template>
  </v-container>
</template>

<script setup lang="ts">
import "vue3-carousel/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { ref, onMounted } from "vue";
import type { ProcessedMediaItem } from "../../composables/useWordPressMedia";
import { useWordPressMedia } from "../../composables/useWordPressMedia";

// Debug mode toggle
const debugMode = ref(false);

// Loading and error states
const isLoading = ref(true);
const error = ref<string | null>(null);
const mediaItems = ref<ProcessedMediaItem[]>([]);

// Carousel configuration
const carouselSettings = {
  itemsToScroll: 1,
  mouseDrag: true,
  touchDrag: true,
  wrapAround: true,
  autoplay: 3000,
  pauseAutoplayOnHover: true,
};

// Responsive breakpoints
const breakpoints = {
  0: {
    itemsToShow: 1,
  },
  600: {
    itemsToShow: 2,
  },
  960: {
    itemsToShow: 2.5,
  },
  1264: {
    itemsToShow: 3,
  },
};

// Computed prop combines settings and responsive breakpoints
const carouselConfig = {
  ...carouselSettings,
};

// Helper function for srcset
const getResponsiveSrcSet = (media: ProcessedMediaItem) => {
  let srcset = "";
  const availableSizes = Object.entries(media.sizes);

  // Manually map WordPress size names to width values
  const sizeWidths: Record<string, number> = {
    thumbnail: 150,
    medium: 300,
    medium_large: 768,
    large: 1024,
    full: 1500, // Approximate for full size
  };

  // Build srcset with known size widths
  availableSizes.forEach(([size, url]) => {
    if (sizeWidths[size]) {
      srcset += `${url} ${sizeWidths[size]}w, `;
    }
  });

  return srcset.trim().replace(/,\s*$/, "");
};

const getBestImageUrl = (media: ProcessedMediaItem) => {
  if (!media || !media.sizes)
    return "/assets/images/banner-gotong-royong-indonesia.png";

  if (media.sizes.medium) return media.sizes.medium;
  if (media.sizes.large) return media.sizes.large;
  if (media.url) return media.url;

  return "/assets/images/banner-gotong-royong-indonesia.png";
};

// Media loading function
const { fetchMediaFromFolder } = useWordPressMedia();
const loadFolderMedia = async () => {
  isLoading.value = true;
  error.value = null;

  try {
    const media = await fetchMediaFromFolder({
      perPage: 6,
    });
    mediaItems.value = media;
  } catch (err) {
    error.value = "Gagal memuat media. Silakan coba lagi.";
    console.error("Error loading media:", err);
  } finally {
    isLoading.value = false;
  }
};

// Load media on component mount
onMounted(() => {
  loadFolderMedia();
});
</script>

<style scoped>
.documentation-carousel {
  position: relative;
  padding-bottom: 40px; /* Space for pagination */
}

.carousel-image {
  transition: transform 0.3s ease;
}

.v-card:hover .carousel-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.v-card:hover .image-overlay {
  opacity: 1;
}

/* Override carousel pagination styles */
:deep(.carousel__pagination) {
  padding-top: 16px;
}

:deep(.carousel__pagination-button) {
  background-color: rgba(var(--v-theme-primary), 0.3);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 6px;
}

:deep(.carousel__pagination-button--active) {
  background-color: rgb(var(--v-theme-primary));
  transform: scale(1.2);
}

:deep(.carousel__prev),
:deep(.carousel__next) {
  color: rgb(var(--v-theme-primary));
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
</style>
